import req from './src/pages/**/*.tsx?#path'
import Root from '@/pages/_app.js'
import { WrapperPage } from '@/layouts/WrapperPage/WrapperPage.js'
import { lazy } from 'react'

global.middleware = [Root]

const config = {}, components = {}

for (const fileName of req) {
  const path = fileName.replace('src/pages', '').replace('/index', '').replace('.tsx', '')

  let p = path
  
  p += '/'

  components[p + 'page'] = {
    id: p + 'page',
    name: 'Page',
    props: {
      title: p,
      route: p,
      children: [p],
    },
    children: [p],
  }

  components[p] = {
    id: p,
    name: p,
    props: {
      children: ["4dec60b5-a58b-45a0-ab69-0e45c93931e0"],
    },
    children: ["4dec60b5-a58b-45a0-ab69-0e45c93931e0"],
  }

  const { js } = global.entrypoints['pages' + p] || {}

  const src = '/' + js[0]

  config[p] = {
    Component: WrapperPage(lazy(() => import(src))),
    schema: {
      $title: `Страница ${p}`,
      props: {
        $title: `Страница ${p}`,
        children: Object.assign([{ $type: 'component' }], {
          $title: 'Вложенность',
          $type: 'tree',
        }),
      },
    },
  }
}

if (global.builderStore) {
  global.builderStore.dispatch({
    type: 'app/setItems',
    payload: [
      [['components'], (prev = {}) => ({ ...prev, ...components })],
    ],
  })
} else {
  global.REDUX = { components: { ...components } };
}

global.components = Object.assign(global.components || {}, config)
