import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div(() => ({
  minHeight: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}))

export const WrapperPage =
  (Render) =>
  ({ children, ...other }) =>
    (
      <Wrapper>
        <Render {...other} />
        {children}
      </Wrapper>
    )
